import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import { FormGroup, FormControl } from '@angular/forms';
import {Auth0StoreService, CollaboratorsApiService} from "@app/core/services";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SnackBarComponent} from "@app/shared/components";
import type { PatientPrioritySource } from '@app/core/models/import.model';
import { TemplatesApiService } from '@app/diagnosis/services/templates-api.service';
import { ImportSettings } from '@app/core/models/import.model';
import { OrganizationSettingsApiService } from '@app/core/services/organization-settings-api.service';
import { disable } from 'cornerstone-core';

@Component({
  selector: 'app-import-setting',
  templateUrl: './import-setting.component.html',
  styleUrls: ['./import-setting.component.scss']
})
export class ImportSettingComponent implements OnInit {
  userRole!: string | undefined;
  lastSavedValue!: ImportSettings;
  loaded = false;

  sharedFacilities!: { value: string; label: string }[];
  templates!: { value: string; label: string }[];

  private readonly destroy$ = new Subject<void>();

  form = new FormGroup({
    patientPrioritySource: new FormControl<PatientPrioritySource>('dicom', {nonNullable: true}),
    selectedFacility: new FormControl<string>('', {nonNullable: true}),
    selectedTemplate: new FormControl<string>('', {nonNullable: true}),
  });

  constructor(
    private organizationSettingsApiService: OrganizationSettingsApiService,
    private auth0StoreService: Auth0StoreService,
    private matSnackBar: MatSnackBar,
    private collaboratorsApiService: CollaboratorsApiService,
    private templatesApiService: TemplatesApiService,
  ) { }

  async ngOnInit() {
    this.loaded = false;

    this.auth0StoreService.currentUserRoleSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((role) => {
        this.userRole = role;
        if (role === 'Admin') {
          this.form.enable();
        } else {
          this.form.disable();
        }
      });

    const collaborators = await this.collaboratorsApiService.getCollaborators();
    const { templates } = await this.templatesApiService.getTemplates();

    this.sharedFacilities = collaborators.to?.map((collaborator) => {
      return {
        value: collaborator.id,
        label: collaborator.displayName,
      };
    });

    this.templates = templates.map((template) => {
      return {
        value: template.id,
        label: template.name,
      };
    });

    const defaultTemplate = templates.find((template) => template.isDefaultTemplate);
    if (defaultTemplate) {
      this.form.patchValue({selectedTemplate: defaultTemplate.id});
    }

    const { importSettings: currentSettings } = await this.organizationSettingsApiService.getSettings();
    this.form.patchValue(currentSettings || {});
    this.lastSavedValue = this.form.getRawValue();

    this.loaded = true;
  }

  async submit() {
    await this.organizationSettingsApiService.saveSettings({importSettings: this.form.getRawValue()});

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'インポートの設定を保存しました',
      },
      duration: 3 * 1000,
    });

    this.lastSavedValue = this.form.getRawValue();
  }

  hasChanges(): boolean {
    return JSON.stringify(this.lastSavedValue) !== JSON.stringify(this.form.getRawValue());
  }
}
